import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import car from '../../assets/Car.png';
import riderlogo from '../../assets/riderlogo.png';
import './RiderContainer.scss';

const RiderContainer = () => {

    const params = useParams();

    const handleRiderDownload = () => {
        const isSafari =
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === '[object SafariRemoteNotification]';
            })(
                !window.safari ||
                (typeof safari !== 'undefined' && window.safari.pushNotification)
            );
        const isIphone = /(iPhone)/i.test(navigator.userAgent);

        if (window.innerWidth > 900) {
            if (isSafari) {
                window.location = 'https://apps.apple.com/app/drife/id1563395160';
                return;
            }

            window.location =
                'https://play.google.com/store/apps/details?id=com.drifebeta';
            return;
        }

        if (params) {
            const { id } = params;
            if (isIphone) {
                window.location = 'https://apps.apple.com/app/drife/id1563395160';
                return;
            }
            window.location = `https://play.google.com/store/apps/details?id=com.drifebeta&referrer=${id}`;
            return;
        }

        if (isIphone || isSafari) {
            window.location = 'https://apps.apple.com/app/drife/id1563395160';
            return;
        }

        window.location =
            'https://play.google.com/store/apps/details?id=com.drifebeta';
    };

    useEffect(() => {
        document.title = 'Rider Container';
    }, []);

    return (
        <div className="emailVerify">
            <div className="background">
                <div className="section">
                    <div className="container">
                        <div className="information">
                            <div className="sentInfo">
                                <div className="sentInfoText" />
                                <div className="sentInfoText" />
                            </div>
                        </div>
                        <div className="card">
                            <img className="Image" src={car} alt="car" />
                            <div className="title" style={{ color: '#11ADDF' }}>
                                Download DRIFE now
                            </div>
                            <div className="subTitleConatiner">
                                <div className="subTitle">
                                    You have been referred by your friend to download the DRIFE
                                    App
                                </div>
                            </div>
                            <div className="downloadContainer">
                                <div className="riderContainer">
                                    <div>
                                        <img className="driver" src={riderlogo} alt="rider" />
                                    </div>
                                    <div
                                        className="buttonContainer"
                                        role="button"
                                        tabIndex={-2}
                                        onClick={handleRiderDownload}
                                        onKeyDown={handleRiderDownload}
                                    >
                                        <div className="button">Download Rider App</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            copyright 2024 Drife Technologies
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiderContainer;
