import React from 'react';
import { useParams } from 'react-router-dom';
import car from '../../assets/Car.png';
import driverlogo from '../../assets/driverlogo.png';
import riders from '../../assets/riders.png';
import riderlogo from '../../assets/riderlogo.png';
import arrow from '../../assets/arrow.png';
import Background from '../../assets/Background.mp4';
import './HomeContainer.scss';

const HomeContainer = () => {

    const params = useParams();

    const handleDriverDownload = () => {
        
        if (window.innerWidth > 900) {
            window.location = 'https://play.google.com/store/apps/details?id=com.drifepartnerbeta';
            return;
        }

        if (params) {
            const { id } = params;
            window.location = `https://play.google.com/store/apps/details?id=com.drifepartnerbeta&referrer=${id}`;
            return;
        }

        window.location = 'https://play.google.com/store/apps/details?id=com.drifepartnerbeta';
    };

    const handleRiderDownload = () => {
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification)));
        const isIphone = /(iPhone)/i.test(navigator.userAgent);

        if (window.innerWidth > 900) {
            if (isSafari) {
                window.location = 'https://apps.apple.com/app/drife/id1563395160';
                return;
            }
            window.location = 'https://play.google.com/store/apps/details?id=com.drifebeta';
            return;
        }

        if (params) {
            const { id } = params;
            if (isIphone) {
                window.location = 'https://apps.apple.com/app/drife/id1563395160';
                return;
            }
            window.location = `https://play.google.com/store/apps/details?id=com.drifebeta&referrer=${id}`;
            return;
        }

        if (isIphone || isSafari) {
            window.location = 'https://apps.apple.com/app/drife/id1563395160';
            return;
        }

        window.location = 'https://play.google.com/store/apps/details?id=com.drifebeta';
    };



    return (
        <div className="homeContainer">
            <video autoPlay muted loop id="video">
                    <source src={Background} type="video/mp4" />
            </video>
            <div className="container">
                <div className="card">
                    <div className="title">Download DRIFE Now</div>
                    <div className="subTitleConatiner">
                        <div className="subTitle">You have been referred by your friend to download the DRIFE App</div>
                    </div>
                    <div className="downloadContainer">
                        <div className="driverContainer">
                            <div className='imgContainer'>
                                <img className='driverlogo' src={driverlogo} alt="driver" />
                                <div className='img'>
                                    <img className="driver" src={car} alt="car" />
                                </div>
                            </div>
                            <div className="arrowContainer">
                                <div className="buttonContainer" role="button" onClick={handleDriverDownload}>
                                    <span className='header'>DRIFE Driver </span>
                                    <img className="arrow" src={arrow} alt="arrow" />
                                </div>
                                <div className='ptag'>
                                    <p className='text'>Download drive app to take rides, see your earning and incentives </p>
                                </div>
                            </div>
                        </div>
                        <div className="driverContainer">
                            <div className="imgsContainer">
                                <img className="riderlogo" src={riderlogo} alt="riders" />
                                <div className='imgs'>
                                    <img className="rider" src={riders} alt="car" />
                                </div>
                            </div>
                            <div className="arrowContainer">
                                <div className="buttonContainer" role="button" onClick={handleRiderDownload}>
                                    <span className='header'  >DRIFE Rider </span>
                                    <img className="arrow" src={arrow} alt="arrow" />
                                </div>
                                <div className='ptag'>
                                    <p className='text'> Download Rider app to Book Cabs and Earn DRFs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">copyright 2024 Drife Technologies</div>
            </div>
        </div>
    );
};

export default HomeContainer;
