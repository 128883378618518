import 'whatwg-fetch';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import DriverContainer from './routes/DriverContainer';
import HomeContainer from './routes/HomeContainer';
import RiderContainer from './routes/RiderContainer';
import './App.scss';

const history = createBrowserHistory();

const App = () => {
    return (
        <div className='mainContainer'>
            <Router history={history}>
                <Routes>
                    <Route exact path="/driver" element={<DriverContainer />} />
                    <Route exact path="/driver/:id" element={<DriverContainer />} />
                    <Route exact path="/rider/:id" element={<RiderContainer />} />
                    <Route exact path="/rider" element={<RiderContainer />} />
                    <Route exact path="/:id" element={<HomeContainer />} />
                    <Route exact path="/" element={<HomeContainer />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;


